
import { defineComponent, onMounted, computed, ref } from 'vue'

import Invoice from '@/core/services/Callable/Invoice';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from '@/router/clean';
import { showHide } from '@/store/stateless';
import { store } from "@/store";

export default defineComponent({
    name: 'invoice-list',

    setup() {

        const filter = computed(() => {
            return store.state.invoice.filter
        })

        const permissions = computed(() => {
            return store.getters.getUserPermissions
        })
        
        onMounted(async () => {
            setCurrentPageBreadcrumbs("Invoices For Approval", [{ label: "Invoices", value: '' }]);
            const page = router.currentRoute.value.query ? router.currentRoute.value.query.page : 1;
            if (!store.getters.showByRole([1]) && permissions.value.is_sales_agent) filter.value.agent = store.getters.myId
            
            
            
            await getLists(page);
        })

        const getLists = async (e: any) => {
            const page = parseInt(e ? e : 1);
            
            filter.value.per_page = 50;
            filter.value.is_amend = -1;
            filter.value.is_discrepancy = 0;
            filter.value.keyword = "";
            filter.value.start_date = "";
            filter.value.end_date = "";
            filter.value.is_manual_order = 0;

            filter.value.page = page;
            filter.value.status = 1;
            filter.value.payment_type = [2, 3, 4, 5, 6 , 8, 9];
            
            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            await Invoice.invoiceLists(filter.value)
        }

        const silentReferesh = () => {
            Invoice.invoiceLists(filter.value)
        }

        return {
            filter,
            showHide,
            store,
            silentReferesh
        }
    },
})
